import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { useTrackTitanHydratedStore } from 'src/providers/track-titan-store-provider';

const useRedirectNewsUser = (router: AppRouterInstance, newSession: boolean, parameterOnly = false) => {
    const { user, isCheckingAuth } = useTrackTitanHydratedStore((state) => state.auth);
    const path = usePathname();

    useEffect(() => {
        if (newSession && parameterOnly) {
            if (user && user.userType === 'news_user') {
                router.push('/news');
            }
        } else if (!isCheckingAuth && !parameterOnly && !path?.includes('/dashboard')) {
            if (user) {
                router.push(user.userType === 'news_user' ? '/news' : '/dashboard');
            } else {
                router.push('/register');
            }
        }
    }, [isCheckingAuth, router, user, newSession, parameterOnly]);
};

export { useRedirectNewsUser };
