'use client';

import LoadingAdvice from 'components/LoadingAdvice';
import { useRedirectNewsUser } from 'src/hooks/useRedirectNewsUser';
import { useRouter } from 'next/navigation';

const ClientHome = ({ loadingAdvice, newSession }: { loadingAdvice: string; newSession: boolean }) => {
    const router = useRouter();
    useRedirectNewsUser(router, newSession);

    return (
        <div className="flex w-full h-full flex-col items-center justify-center px-4">
            <LoadingAdvice message={loadingAdvice} />
        </div>
    );
};

export default ClientHome;
